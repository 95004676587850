<div id="howto" class="software-integrations-area bg-gradient-color ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-12">
        <div class="new-app-main-banner-wrap-image">
          <img src="assets/img/how-to/banner.png" alt="image" />
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="software-integrations-content white-color">
          <span class="sub-title">1 Click SaaS Deployments</span>
          <h2>Easy & Perfect SaaS deployments are here and Ready for You!</h2>
          <p>
            Learn how to quickly deploy Cloud based Mobile & Web SaaS Apps
            through our "How it Works" page.
          </p>
          <p>
            Fully customizable SaaS Apps that you control and administer is just
            a few clicks away. Every SaaS App gets it's own app space!
          </p>
          <p>Be ready for the next BIG mobile app release coming soon.</p>
          <a routerLink="/how-to" class="default-btn" style="margin-right: 5px"
            >How it Works?</a
          >
          <a
            href="https://portal.appworldsa.com/"
            target="_blank"
            style="margin-right: 10px"
            class="default-btn-2"
            >Portal Login</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="shape8">
    <img src="assets/img/shape/shape7.png" alt="shape" />
  </div> -->
</div>
