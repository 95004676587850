<div class="banner-area">
  <div class="container-fluid">
    <div class="row align-items-center m-0">
      <div class="col-lg-6 col-md-12 p-0">
        <div class="banner-content">
          <span class="sub-title">DOWNLOAD APP</span>
          <h1>Get Test App and create your Own Study Tests</h1>
          <p>
            Download our Test App to get first hand experience of one of our
            custom built apps. All our apps are fully integrated with a backend
            micro services ecosystem to manage users, content and scale on
            demand.
          </p>
          <div class="new-app-download-content">
            <div class="btn-box color-wrap">
              <a
                href="https://play.google.com/store/apps/details?id=com.appworldsa.test"
                class="playstore-btn"
                target="_blank"
                style="margin-bottom: 15px"
              >
                <img src="assets/img/play-store.png" alt="image" />
                Get It On
                <span>Google Play</span>
              </a>
              <a
                href="https://testflight.apple.com/join/tkhQSqgK"
                class="applestore-btn"
                target="_blank"
                style="margin-bottom: 15px"
              >
                <img src="assets/img/apple-store.png" alt="image" />
                Download on the
                <span>Apple Store</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 p-0">
        <div class="banner-image">
          <img src="assets/img/apps/test/banner.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
  <div class="banner-shape3">
    <img src="assets/img/shape/shape2.png" alt="image" />
  </div>
  <div class="shape13">
    <img src="assets/img/shape/shape15.png" alt="shape" />
  </div>
  <div class="banner-shape6">
    <img src="assets/img/shape/shape16.png" alt="image" />
  </div>
  <div class="banner-shape7">
    <img src="assets/img/shape/shape11.png" alt="image" />
  </div>
  <div class="banner-shape8">
    <img src="assets/img/shape/shape11.png" alt="image" />
  </div>
</div>
