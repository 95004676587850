<div id="demos" class="blog-area pt-100 pb-75">
  <div class="container">
    <div class="section-title">
      <h1 class="sub-title" style="font-size: 80px; font-weight: 900">08+</h1>
      <h2>Showcase Apps</h2>
      <h6>
        We create custom software for you. Register your FREE account today and
        create your own mobile app.
      </h6>
      <h6>
        See release matrix for all
        <a style="color: dodgerblue" href="/#/matrix">download links</a>
      </h6>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/demo/test" class="d-block">
              <img src="assets/img/apps/test/page.png" alt="demo" />
            </a>
            <a routerLink="/demo/test" class="tag">Test (Production)</a>
          </div>
          <div class="content">
            <h3>
              <a routerLink="/demo/test"
                >Test app for creating and sharing your online tests or
                quizzes.</a
              >
            </h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/demo/lms" class="d-block">
              <img src="assets/img/apps/lms/page.png" alt="demo" />
            </a>
            <a routerLink="/demo/lms" class="tag">Learning (Alpha)</a>
          </div>
          <div class="content">
            <h3>
              <a routerLink="/demo/lms"
                >Learning app for creating and sharing your courses with
                built-in quiz creator.</a
              >
            </h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/demo/podcast" class="d-block">
              <img src="assets/img/apps/podcast/page.png" alt="demo" />
            </a>
            <a routerLink="/demo/podcast" class="tag">Streaming (POC)</a>
          </div>
          <div class="content">
            <h3>
              <a routerLink="/demo/podcast"
                >Podcast app for listening to daily podcast or uploading your
                own content for a dedicated audience.</a
              >
            </h3>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/demo/hotel" class="d-block">
              <img src="assets/img/apps/hotel/page.png" alt="demo" />
            </a>
            <a routerLink="/demo/hotel" class="tag">Accommodation (POC)</a>
          </div>
          <div class="content">
            <h3>
              <a routerLink="/demo/hotel"
                >Hotel Booking app for accommodation reservations and hotel
                bookings.</a
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/demo/nft" class="d-block">
              <img src="assets/img/apps/nft/page.png" alt="demo" />
            </a>
            <a routerLink="/demo/nft" class="tag">Crypto NFT (POC)</a>
          </div>
          <div class="content">
            <h3>
              <a routerLink="/demo/nft"
                >Crypto NFT app to buy and sell NFT's on Ethereum Mainnet.</a
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/demo/shop" class="d-block">
              <img src="assets/img/apps/shop/page.png" alt="demo" />
            </a>
            <a routerLink="/demo/shop" class="tag">Ecommerce (POC)</a>
          </div>
          <div class="content">
            <h3>
              <a routerLink="/demo/shop"
                >Online shop to buy and sell products online with payment
                integrations.</a
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/demo/medical" class="d-block">
              <img src="assets/img/apps/medical/page.png" alt="demo" />
            </a>
            <a routerLink="/demo/medical" class="tag">Medical (POC)</a>
          </div>
          <div class="content">
            <h3>
              <a routerLink="/demo/medical"
                >Medical App for booking virtual consultations with nearby
                doctors.</a
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/demo/wallet" class="d-block">
              <img src="assets/img/apps/wallet/page.png" alt="demo" />
            </a>
            <a routerLink="/demo/wallet" class="tag">Wallet (POC)</a>
          </div>
          <div class="content">
            <h3>
              <a routerLink="/demo/wallet"
                >Finance Wallet App for managing your finance more
                effectively.</a
              >
            </h3>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/blog-details" class="d-block">
              <img src="assets/img/demo/home7.jpg" alt="demo" />
            </a>
            <a routerLink="/blog-grid" class="tag">Stock</a>
          </div>
          <div class="content">
            <h3>
              <a routerLink="/blog-details"
                >Delivery app for managing inventory, delivering goods to
                clients and tracking progress.</a
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/blog-details" class="d-block">
              <img src="assets/img/demo/home8.jpg" alt="demo" />
            </a>
            <a routerLink="/blog-grid" class="tag">Support</a>
          </div>
          <div class="content">
            <h3>
              <a routerLink="/blog-details"
                >Call-center app for creating support tickets, tracking issues
                and analyse performance.</a
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-blog-post">
          <div class="image">
            <a routerLink="/blog-details" class="d-block">
              <img src="assets/img/demo/home9.jpg" alt="demo" />
            </a>
            <a routerLink="/blog-grid" class="tag">Medical</a>
          </div>
          <div class="content">
            <h3>
              <a routerLink="/blog-details"
                >A medical app for recording patient information, store
                measurements and create prescriptions</a
              >
            </h3>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
