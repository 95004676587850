<app-navbar-light></app-navbar-light>

<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>How It Works?</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>How It Works</li>
      </ul>
    </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
  <div class="banner-shape1">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div>
</div>

<div class="how-it-works-area ptb-100">
  <div class="container">
    <div class="how-it-works-content">
      <div class="number">1</div>
      <div class="row m-0">
        <div class="col-lg-3 col-md-12 p-0">
          <div class="box">
            <h3>Step 1</h3>
            <span>Register your AppWorld Account</span>
          </div>
        </div>
        <div class="col-lg-9 col-md-12 p-0">
          <div class="content">
            <ul>
              <li>
                <strong>Visit the Registration Page:</strong> Click on the "<a
                  href="https://portal.appworldsa.com/"
                  target="_blank"
                  >Portal Login</a
                >" button at the top right corner to navigate to the
                registration page.
              </li>
              <li>
                <strong>Enter Your Details:</strong> Sign up with one of our SSO
                providers or by filling in the required fields, including your
                name, email address, and a secure password. Make sure your
                information is accurate to ensure a smooth registration process.
              </li>
              <li>
                <strong>Start Exploring:</strong> With your account set up, you
                can now explore the wide range of apps available on AppWorld.
                Download, install, and enjoy!
              </li>
            </ul>

            <img src="assets/img/how-to/register.jpg" alt="create-account" />
          </div>
        </div>
      </div>
    </div>
    <div class="how-it-works-content">
      <div class="number">2</div>
      <div class="row m-0">
        <div class="col-lg-3 col-md-12 p-0">
          <div class="box">
            <h3>Step 2</h3>
            <span>Create a SaaS App from the Marketplace</span>
          </div>
        </div>
        <div class="col-lg-9 col-md-12 p-0">
          <div class="content">
            <ul>
              <li>
                <strong>Visit Marketplace:</strong> Once you have access to your
                account, you can head over to the
                <a
                  href="https://portal.appworldsa.com/#/app/main/apps/marketplace"
                  target="_blank"
                  >Marketplace</a
                >
                to create your first SaaS App.
              </li>
              <li>
                <strong>Create SaaS App:</strong> Click on the "Create" button
                and fill in your App information. Please note that the
                <span style="color: dodgerblue">Unique Handle (Url)</span>
                will be specific to you app instance.
              </li>
              <li>
                <strong>Login to App:</strong> After your new App has been
                created, select "Manage App" to do an impersonated login to your
                App as the admin account for managing your App. Your current
                account session will be replaced.
              </li>
              <li>
                <strong>Back to My Apps:</strong> To get back to your current
                session you can select the "Back to my account" option top right
                of the screen.
              </li>
            </ul>
            <img src="assets/img/how-to/marketplace.jpg" alt="select-coin" />
          </div>
        </div>
      </div>
    </div>
    <div class="how-it-works-content">
      <div class="number">3</div>
      <div class="row m-0">
        <div class="col-lg-3 col-md-12 p-0">
          <div class="box">
            <h3>Step 3</h3>
            <span>Customize your new App</span>
          </div>
        </div>
        <div class="col-lg-9 col-md-12 p-0">
          <div class="content">
            <ul>
              <li>
                <strong>Explore Features:</strong> Every App is unqiue in terms
                of the options available for you to customize. For example a
                podcast app will allow you to create and manage channels, upload
                new episodes and manage metadata.
              </li>
              <li>
                <strong>Customize your Brand:</strong> You can customize your
                App by changing your Company Logo, App Icon and App name.
              </li>
              <li>
                <strong>Setup your SaaS App:</strong> We offer a variety of
                customization for you. Feel free to explore all the options
                available and reach out to us if you need more help. We're
                always happy to assist.
              </li>
            </ul>
            <img src="assets/img/how-to/customize.jpg" alt="select-payment" />
          </div>
        </div>
      </div>
    </div>
    <div class="how-it-works-content">
      <div class="number">4</div>
      <div class="row m-0">
        <div class="col-lg-3 col-md-12 p-0">
          <div class="box">
            <h3>Step 4</h3>
            <span>Download the mobile App and be your first user</span>
          </div>
        </div>
        <div class="col-lg-9 col-md-12 p-0">
          <div class="content">
            <ul>
              <li>
                <strong>Your App Website:</strong> You can test your app on the
                web by clicking on the ↗️ seen on the top right of your screen.
              </li>
              <li>
                <strong>Download from App Store:</strong> Every App has a shared
                instance available for you to download. Once you have the App
                downloaded you will need to connect to your specific instance.
                Follow to prompts on the mobile app to get connected.
              </li>
              <li>
                <strong>Publish your own App:</strong> Need to have your App
                published under your own name or company name? We can assist by
                building you a version of your SaaS App that will automatically
                connect directly to your admin portal. To get assistance on this
                please reach out to us.
              </li>
            </ul>
            <img src="assets/img/how-to/download.jpg" alt="payment-method" />
          </div>
        </div>
      </div>
    </div>
    <div class="how-it-works-content">
      <div class="number">5</div>
      <div class="row m-0">
        <div class="col-lg-3 col-md-12 p-0">
          <div class="box">
            <h3>Step 5</h3>
            <span>Share with the world</span>
          </div>
        </div>
        <div class="col-lg-9 col-md-12 p-0">
          <div class="content">
            <ul>
              <li>
                <strong>Share with Friends:</strong> Let your friends know how
                to get your App. Each App has a unique version published the the
                selected stores.
              </li>
              <li>
                <strong>Deep links:</strong> Use deep links to directly connect
                to your app version when launching the App. Additionally share
                your Access Code with you friends to streamline their access
                configuration.
              </li>
            </ul>
            <img src="assets/img/how-to/share.jpg" alt="confirm-order" />
          </div>
        </div>
      </div>
    </div>
    <div class="how-it-works-content">
      <div class="number">5</div>
      <div class="row m-0">
        <div class="col-lg-3 col-md-12 p-0">
          <div class="box">
            <h3>Step 5</h3>
            <span>Need more?</span>
          </div>
        </div>
        <div class="col-lg-9 col-md-12 p-0">
          <div class="content">
            <ul>
              <li>
                Enjoying the apps from the marketplace but it's not quite what
                you need? Reach out to us through the "<a
                  href="http://localhost:4201/#/contact-us"
                  target="_blank"
                  >Get In Touch</a
                >" option for custom development. We're always happy to assist.
              </li>
            </ul>
            <img src="assets/img/how-to/contact.jpg" alt="confirm-order" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="app-download-area pb-100">
  <app-download></app-download>
</div>

<app-footer></app-footer>
