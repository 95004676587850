<div class="gradient-banner-area">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="gradient-banner-content">
          <h1>Finance Wallet Demo App</h1>
          <p>
            Get a feel of our apps by downloading and exploring the look and
            feel of our published demo apps.
          </p>
          <div class="new-app-download-content">
            <div class="btn-box color-wrap">
              <a
                href="https://play.google.com/apps/testing/com.appworldsa.wallet.demo"
                class="playstore-btn"
                target="_blank"
                style="margin-bottom: 15px"
              >
                <img src="assets/img/play-store.png" alt="image" />
                Get It On
                <span>Google Play</span>
              </a>
              <a
                href="https://testflight.apple.com/join/12RzaKx3"
                class="applestore-btn"
                target="_blank"
                style="margin-bottom: 15px"
              >
                <img src="assets/img/apple-store.png" alt="image" />
                Download on the
                <span>Apple Store</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="gradient-banner-image" data-aos="fade-up">
          <img src="assets/img/apps/wallet/banner.png" alt="banner-img" />
        </div>
      </div>
    </div>
  </div>
  <div class="banner-shape2">
    <img src="assets/img/shape/shape7.png" alt="image" />
  </div>
  <div class="banner-shape9">
    <img src="assets/img/shape/shape21.png" alt="image" />
  </div>
  <div class="banner-shape10">
    <img src="assets/img/shape/shape21.png" alt="image" />
  </div>
  <div class="banner-shape11">
    <img src="assets/img/shape/shape22.png" alt="image" />
  </div>
  <div class="banner-shape3">
    <img src="assets/img/shape/shape2.png" alt="image" />
  </div>
</div>
