import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './main/common/not-found/not-found.component';
import { HomeComponent } from './main/home/home.component';
import { PrivacyPolicyComponent } from './main/support/privacy/privacy-policy.component';
import { TermsComponent } from './main/support/terms/terms.component';
import { ContactUsComponent } from './main/support/contact/contact-us.component';
import { PodcastComponent } from './main/home/pages/podcast/podcast.component';
import { HotelComponent } from './main/home/pages/hotel/hotel.component';
import { NFTComponent } from './main/home/pages/nft/nft.component';
import { ShopComponent } from './main/home/pages/shop/shop.component';
import { MedicalComponent } from './main/home/pages/medical/medical.component';
import { WalletComponent } from './main/home/pages/wallet/wallet.component';
import { APIComponent } from './main/api/api.component';
import { HowToComponent } from './main/support/how-to/how-to.component';
import { PrivacyContentComponent } from './main/support/privacy/privacy-content/privacy-content.component';
import { TermsContentComponent } from './main/support/terms/terms-content/terms-content.component';
import { LMSComponent } from './main/home/pages/lms/lms.component';
import { FaqComponent } from './main/support/faq/faq.component';
import { Faq2Component } from './main/support/faq/faq-2/faq2.component';
import { TestComponent } from './main/home/pages/test/test.component';
import { MatrixComponent } from './main/support/matrix/matrix.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'privacy-policy-content', component: PrivacyContentComponent },
  { path: 'terms-of-service', component: TermsComponent },
  { path: 'terms-of-service-content', component: TermsContentComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'how-to', component: HowToComponent },
  { path: 'api', component: APIComponent },
  { path: 'demo/podcast', component: PodcastComponent },
  { path: 'demo/hotel', component: HotelComponent },
  { path: 'demo/nft', component: NFTComponent },
  { path: 'demo/shop', component: ShopComponent },
  { path: 'demo/medical', component: MedicalComponent },
  { path: 'demo/wallet', component: WalletComponent },
  { path: 'demo/lms', component: LMSComponent },
  { path: 'demo/test', component: TestComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'faq-2', component: Faq2Component },
  { path: 'matrix', component: MatrixComponent },

  // { path: 'index-1', component: HomeDemoOneComponent },
  // { path: 'index-2', component: HomeDemoTwoComponent },
  // { path: 'index-3', component: HomeDemoThreeComponent },
  // { path: 'index-4', component: HomeDemoFourComponent },
  // { path: 'index-5', component: HomeDemoFiveComponent },
  // { path: 'index-6', component: HomeDemoSixComponent },
  // { path: 'index-7', component: HomeDemoSevenComponent },
  // { path: 'index-8', component: HomeDemoEightComponent },
  // { path: 'index-9', component: HomeDemoNineComponent },
  // { path: 'about-simple', component: AboutSimplePageComponent },
  // { path: 'about-modern', component: AboutModernPageComponent },
  // { path: 'features-1', component: FeaturesOnePageComponent },
  // { path: 'features-2', component: FeaturesTwoPageComponent },
  // // { path: 'faq', component: FaqPageComponent },
  // { path: 'sign-in', component: SignInPageComponent },
  // { path: 'sign-up', component: SignUpPageComponent },
  // { path: 'forgot-password', component: ForgetPasswordPageComponent },
  // { path: 'how-it-works', component: HowItWorksPageComponent },
  // { path: 'gallery', component: GalleryPageComponent },
  // { path: 'coming-soon', component: ComingSoonPageComponent },
  // { path: 'services', component: ServicesPageComponent },
  // { path: 'pricing', component: PricingPageComponent },
  // { path: 'feedback', component: ReviewsPageComponent },
  // { path: 'app-download', component: AppDownloadPageComponent },
  // { path: 'screenshots', component: ScreenshotsPageComponent },
  // { path: 'team-1', component: TeamOnePageComponent },
  // { path: 'team-2', component: TeamTwoPageComponent },
  // { path: 'blog-grid', component: BlogGridPageComponent },
  // { path: 'blog-right-sidebar', component: BlogRightSidebarPageComponent },
  // { path: 'blog-left-sidebar', component: BlogLeftSidebarPageComponent },
  // { path: 'blog-details', component: BlogDetailsPageComponent },
  // { path: 'products', component: ProductsPageComponent },
  // { path: 'cart', component: CartPageComponent },
  // { path: 'checkout', component: CheckoutPageComponent },
  // { path: 'products-details', component: ProductsDetailsPageComponent },
  // // { path: 'privacy-policy', component: PrivacyPolicyPageComponent },
  // { path: 'terms-conditions', component: TermsConditionsPageComponent },
  // { path: 'contact', component: ContactPageComponent },
  // // Here add new pages component

  { path: '**', component: NotFoundComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
