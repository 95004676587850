import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountUpModule } from 'ngx-countup';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './main/common/not-found/not-found.component';
import { HomeComponent } from './main/home/home.component';
import { NavbarComponent } from './main/common/navbar/navbar.component';
import { FooterComponent } from './main/common/footer/footer.component';
import { HomeBannerComponent } from './main/home/home-banner/home-banner.component';
import { FeaturesComponent } from './main/home/features/features.component';
import { DemosComponent } from './main/home/demos/demos.component';
import { FrameworksComponent } from './main/home/frameworks/frameworks.component';
import { PrivacyPolicyComponent } from './main/support/privacy/privacy-policy.component';
import { NavbarLightComponent } from './main/common/navbar-light/navbar-light.component';
import { TermsComponent } from './main/support/terms/terms.component';
import { ContactUsComponent } from './main/support/contact/contact-us.component';
import { PodcastComponent } from './main/home/pages/podcast/podcast.component';
import { NavbarDarkComponent } from './main/common/navbar-dark/navbar-dark.component';
import { PodcastBannerComponent } from './main/home/pages/podcast/podcast-banner/podcast-banner.component';
import { PodcastFeaturesComponent } from './main/home/pages/podcast/podcast-features/podcast-features.component';
import { PodcastScreenshotsComponent } from './main/home/pages/podcast/podcast-screenshots/podcast-screenshots.component';
import { PodcastVideoComponent } from './main/home/pages/podcast/podcast-video/podcast-video.component';
import { FaqComponent } from './main/support/faq/faq.component';
import { Faq2Component } from './main/support/faq/faq-2/faq2.component';
import { HttpClientModule } from '@angular/common/http';
import { HotelBannerComponent } from './main/home/pages/hotel/hotel-banner/hotel-banner.component';
import { HotelFeaturesComponent } from './main/home/pages/hotel/hotel-features/hotel-features.component';
import { HotelScreenshotsComponent } from './main/home/pages/hotel/hotel-screenshots/hotel-screenshots.component';
import { HotelVideoComponent } from './main/home/pages/hotel/hotel-video/hotel-video.component';
import { HotelComponent } from './main/home/pages/hotel/hotel.component';
import { NFTBannerComponent } from './main/home/pages/nft/nft-banner/nft-banner.component';
import { NFTFeaturesComponent } from './main/home/pages/nft/nft-features/nft-features.component';
import { NFTScreenshotsComponent } from './main/home/pages/nft/nft-screenshots/nft-screenshots.component';
import { NFTVideoComponent } from './main/home/pages/nft/nft-video/nft-video.component';
import { NFTComponent } from './main/home/pages/nft/nft.component';
import { ShopBannerComponent } from './main/home/pages/shop/shop-banner/shop-banner.component';
import { ShopFeaturesComponent } from './main/home/pages/shop/shop-features/shop-features.component';
import { ShopScreenshotsComponent } from './main/home/pages/shop/shop-screenshots/shop-screenshots.component';
import { ShopVideoComponent } from './main/home/pages/shop/shop-video/shop-video.component';
import { ShopComponent } from './main/home/pages/shop/shop.component';
import { MedicalBannerComponent } from './main/home/pages/medical/medical-banner/medical-banner.component';
import { MedicalFeaturesComponent } from './main/home/pages/medical/medical-features/medical-features.component';
import { MedicalScreenshotsComponent } from './main/home/pages/medical/medical-screenshots/medical-screenshots.component';
import { MedicalVideoComponent } from './main/home/pages/medical/medical-video/medical-video.component';
import { MedicalComponent } from './main/home/pages/medical/medical.component';
import { WalletBannerComponent } from './main/home/pages/wallet/wallet-banner/wallet-banner.component';
import { WalletFeaturesComponent } from './main/home/pages/wallet/wallet-features/wallet-features.component';
import { WalletScreenshotsComponent } from './main/home/pages/wallet/wallet-screenshots/wallet-screenshots.component';
import { WalletVideoComponent } from './main/home/pages/wallet/wallet-video/wallet-video.component';
import { WalletComponent } from './main/home/pages/wallet/wallet.component';
import { HowItWorksComponent } from './main/home/how-it-works/how-it-works.component';
import { HowToComponent } from './main/support/how-to/how-to.component';
import { AppDownloadComponent } from './main/support/how-to/app-download/app-download.component';
import { PrivacyContentComponent } from './main/support/privacy/privacy-content/privacy-content.component';
import { TermsContentComponent } from './main/support/terms/terms-content/terms-content.component';
import { LMSBannerComponent } from './main/home/pages/lms/lms-banner/lms-banner.component';
import { LMSFeaturesComponent } from './main/home/pages/lms/lms-features/lms-features.component';
import { LMSScreenshotsComponent } from './main/home/pages/lms/lms-screenshots/lms-screenshots.component';
import { LMSVideoComponent } from './main/home/pages/lms/lms-video/lms-video.component';
import { LMSComponent } from './main/home/pages/lms/lms.component';
import { TestBannerComponent } from './main/home/pages/test/test-banner/test-banner.component';
import { TestFeaturesComponent } from './main/home/pages/test/test-features/test-features.component';
import { TestScreenshotsComponent } from './main/home/pages/test/test-screenshots/test-screenshots.component';
import { TestVideoComponent } from './main/home/pages/test/test-video/test-video.component';
import { TestComponent } from './main/home/pages/test/test.component';
import { MatrixComponent } from './main/support/matrix/matrix.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    NavbarLightComponent,
    NavbarDarkComponent,
    FooterComponent,
    HomeBannerComponent,
    FeaturesComponent,
    DemosComponent,
    FrameworksComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    FaqComponent,
    Faq2Component,
    ContactUsComponent,
    HowItWorksComponent,
    HowToComponent,
    AppDownloadComponent,
    NotFoundComponent,
    PrivacyContentComponent,
    TermsContentComponent,
    MatrixComponent,

    PodcastComponent,
    PodcastBannerComponent,
    PodcastFeaturesComponent,
    PodcastScreenshotsComponent,
    PodcastVideoComponent,

    HotelComponent,
    HotelBannerComponent,
    HotelFeaturesComponent,
    HotelScreenshotsComponent,
    HotelVideoComponent,

    NFTComponent,
    NFTBannerComponent,
    NFTFeaturesComponent,
    NFTScreenshotsComponent,
    NFTVideoComponent,

    ShopComponent,
    ShopBannerComponent,
    ShopFeaturesComponent,
    ShopScreenshotsComponent,
    ShopVideoComponent,

    MedicalComponent,
    MedicalBannerComponent,
    MedicalFeaturesComponent,
    MedicalScreenshotsComponent,
    MedicalVideoComponent,

    WalletComponent,
    WalletBannerComponent,
    WalletFeaturesComponent,
    WalletScreenshotsComponent,
    WalletVideoComponent,

    LMSComponent,
    LMSBannerComponent,
    LMSFeaturesComponent,
    LMSScreenshotsComponent,
    LMSVideoComponent,

    TestComponent,
    TestBannerComponent,
    TestFeaturesComponent,
    TestScreenshotsComponent,
    TestVideoComponent,

    // AlertmsgComponent,

    // HomeDemoOneComponent,
    // HomeDemoTwoComponent,
    // FooterStyleOneComponent,
    // FooterStyleTwoComponent,
    // FooterStyleThreeComponent,
    // FreeTrialStyleOneComponent,
    // FeedbackStyleOneComponent,
    // PricingStyleOneComponent,
    // ScreenshotsStyleOneComponent,
    // DownloadAppStyleOneComponent,
    // ProgressStyleOneComponent,
    // HomeoneFeaturesComponent,
    // FeaturesStyleOneComponent,
    // HomeoneVideoComponent,
    // HomeoneBannerComponent,
    // NavbarStyleOneComponent,
    // NavbarStyleTwoComponent,
    // HometwoBannerComponent,
    // HometwoFeaturesComponent,
    // HomeoneKeyFeaturesComponent,
    // HometwoKeyFeaturesComponent,
    // ProgressStyleTwoComponent,
    // IntroVideoComponent,
    // ScreenshotsStyleTwoComponent,
    // HometwoSoftwareIntegrationsComponent,
    // PartnerComponent,
    // FreeTrialStyleTwoComponent,
    // PricingStyleTwoComponent,
    // FeedbackStyleTwoComponent,
    // HomeDemoThreeComponent,
    // HomeDemoFourComponent,
    // HomeDemoFiveComponent,
    // HomeDemoSixComponent,
    // HomethreeBannerComponent,
    // HomethreeOverviewComponent,
    // FeaturesStyleTwoComponent,
    // HomethreeFeaturesComponent,
    // HomethreeVideoComponent,
    // ScreenshotsStyleThreeComponent,
    // SoftwareIntegrationsComponent,
    // DownloadAppStyleTwoComponent,
    // PricingStyleThreeComponent,
    // BlogComponent,
    // HomefourBannerComponent,
    // HomefourVideoComponent,
    // HomefourFeaturesComponent,
    // FeaturesStyleThreeComponent,
    // DownloadAppStyleThreeComponent,
    // FeedbackStyleThreeComponent,
    // PricingStyleFourComponent,
    // HomefourSoftwareIntegrationsComponent,
    // ScreenshotsStyleFourComponent,
    // ProgressStyleThreeComponent,
    // FreeTrialStyleThreeComponent,
    // FeedbackStyleFourComponent,
    // PricingStyleFiveComponent,
    // HomefiveBannerComponent,
    // NavbarStyleThreeComponent,
    // ScreenshotsStyleFiveComponent,
    // FeaturesStyleFourComponent,
    // HomefiveAboutComponent,
    // HomefiveFeaturesComponent,
    // FeedbackStyleFiveComponent,
    // ScreenshotsStyleSixComponent,
    // FeaturesStyleFiveComponent,
    // FunfactsStyleOneComponent,
    // FunfactsStyleTwoComponent,
    // HomesixBannerComponent,
    // HomesixAboutComponent,
    // NavbarStyleFourComponent,
    // ContactPageComponent,
    // ContactInfoComponent,
    // BlogDetailsPageComponent,
    // FaqPageComponent,
    // SignInPageComponent,
    // SignUpPageComponent,
    // ForgetPasswordPageComponent,
    // HowItWorksPageComponent,
    // GalleryPageComponent,
    // BlogGridPageComponent,
    // BlogRightSidebarPageComponent,
    // BlogLeftSidebarPageComponent,
    // BlogSidebarComponent,
    // ProductsPageComponent,
    // ProductsDetailsPageComponent,
    // CartPageComponent,
    // CheckoutPageComponent,
    // RelatedProductsComponent,
    // TeamOnePageComponent,
    // TeamTwoPageComponent,
    // ServicesPageComponent,
    // PricingPageComponent,
    // ReviewsPageComponent,
    // PrivacyPolicyPageComponent,
    // TermsConditionsPageComponent,
    // ComingSoonPageComponent,
    // AppDownloadPageComponent,
    // ScreenshotsPageComponent,
    // FeaturesOnePageComponent,
    // FeaturesTwoPageComponent,
    // AboutSimplePageComponent,
    // AboutModernPageComponent,
    // TeamStyleOneComponent,
    // UserStatsComponent,
    // TeamStyleTwoComponent,
    // HomeDemoSevenComponent,
    // HomeDemoEightComponent,
    // HomeDemoNineComponent,
    // HomesevenBannerComponent,
    // HomesevenFeaturesComponent,
    // HomesevenAboutComponent,
    // FeaturesStyleSixComponent,
    // ProgressStyleFourComponent,
    // ScreenshotsStyleSevenComponent,
    // VideoComponent,
    // TrustedFeaturesComponent,
    // PricingStyleSixComponent,
    // DownloadAppStyleFourComponent,
    // FooterStyleFourComponent,
    // ClientReviewsComponent,
    // GetStartedComponent,
    // HomeeightBannerComponent,
    // HomeeightFeaturesComponent,
    // HomeeightAboutComponent,
    // FeaturesStyleSevenComponent,
    // HomeeightScreenshotsComponent,
    // HomeeightIntroComponent,
    // FunfactsStyleThreeComponent,
    // DownloadAppStyleFiveComponent,
    // PricingStyleSevenComponent,
    // FooterStyleFiveComponent,
    // HomeeightBlogComponent,
    // HomenineBannerComponent,
    // HomenineFeaturesComponent,
    // HomenineAppProgressComponent,
    // KeyFeaturesComponent,
    // HomenineBlogComponent,
    // FreeTrialStyleFourComponent,
    // ClientReviewsStyleTwoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxScrollTopModule,
    CarouselModule,
    BrowserAnimationsModule,
    FormsModule,
    CountUpModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  exports: [ReactiveFormsModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
