<div class="new-app-main-banner-area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="new-app-main-banner-content">
          <div class="content">
            <div class="name-big-text">SaaS</div>
            <span class="sub-title">DOWNLOAD APP</span>
            <h1>Download Learning App Now</h1>
            <p>
              Download our Learning App to get first hand experience of one of
              our custom built apps. All our apps are fully integrated with a
              backend micro services ecosystem to manage users, content and
              scale on demand.
            </p>
            <div class="app-btn-box">
              <a
                href="https://testflight.apple.com/join/dtT1swjP"
                class="applestore-btn"
                target="_blank"
              >
                <img src="assets/img/apple-store.png" alt="image" />
                Download on the
                <span>Apple Store</span>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.appworldsa.lms"
                class="playstore-btn"
                target="_blank"
              >
                <img src="assets/img/play-store.png" alt="image" />
                Get It On
                <span>Google Play</span>
              </a>
            </div>
            <div class="content-shape">
              <img
                src="assets/img/more-home/banner/content-shape.png"
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="new-app-main-banner-image">
          <img src="assets/img/apps/lms/banner.png" alt="image" />
          <div class="wrap-shape-1">
            <img src="assets/img/more-home/banner/shape-1.png" alt="image" />
          </div>
          <!-- <div class="wrap-shape-2">
            <img src="assets/img/more-home/banner/shape-2.png" alt="image" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="new-app-banner-bg-shape">
    <img src="assets/img/more-home/banner/banner-shape.png" alt="image" />
  </div>
  <div class="new-app-banner-strock-shape">
    <img src="assets/img/more-home/banner/strock.png" alt="image" />
  </div>
</div>
