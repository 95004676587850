<div class="banner-wrapper-area">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12">
        <div class="banner-wrapper-content">
          <span class="sub-title">Download App</span>
          <h1>Ecommerce Online Shop Demo App</h1>
          <p>
            Get a feel of our apps by downloading and exploring the look and
            feel of our published demo apps.
          </p>
          <div class="new-app-download-content">
            <div class="btn-box color-wrap">
              <a
                href="https://play.google.com/apps/testing/com.appworldsa.shop.demo"
                class="playstore-btn"
                target="_blank"
                style="margin-bottom: 15px"
              >
                <img src="assets/img/play-store.png" alt="image" />
                Get It On
                <span>Google Play</span>
              </a>
              <a
                href="https://testflight.apple.com/join/yt7VEgTr"
                class="applestore-btn"
                target="_blank"
                style="margin-bottom: 15px"
              >
                <img src="assets/img/apple-store.png" alt="image" />
                Download on the
                <span>Apple Store</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-12">
        <div class="banner-wrapper-image">
          <img src="assets/img/apps/shop/banner-1.png" alt="banner-img" />
          <img
            src="assets/img/apps/shop/banner-2.png"
            data-aos="fade-left"
            alt="banner-img"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="shape13">
    <img src="assets/img/shape/shape15.png" alt="shape" />
  </div>
  <div class="shape14">
    <img src="assets/img/shape/shape17.png" alt="shape" />
  </div>
  <div class="shape15">
    <img src="assets/img/shape/shape18.png" alt="shape" />
  </div>
</div>
