<div class="navbar-area navbar-style-two" [ngClass]="{ sticky: isSticky }">
  <div class="container">
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light"
      [class.active]="classApplied"
    >
      <a class="navbar-brand" routerLink="/"
        ><img
          src="assets/img/black-logo.png"
          style="max-height: 60px"
          alt="logo"
      /></a>
      <button class="navbar-toggler" type="button" (click)="toggleClass()">
        <span class="burger-menu">
          <span class="top-bar"></span>
          <span class="middle-bar"></span>
          <span class="bottom-bar"></span>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              routerLink="/"
              class="nav-link"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a href="/#/matrix" class="nav-link">Downloads</a>
          </li>
          <li class="nav-item">
            <a href="/#/api" class="nav-link" target="_blank">API Docs ↗️</a>
          </li>
        </ul>
        <div class="others-option">
          <a
            href="https://portal.appworldsa.com/"
            target="_blank"
            style="margin-right: 10px"
            class="default-btn-2"
            >Portal Login</a
          >
          <a routerLink="/contact-us" class="default-btn">Get In Touch</a>
        </div>
      </div>
    </nav>
  </div>
</div>
