import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-video',
  templateUrl: './test-video.component.html',
  styleUrls: ['./test-video.component.scss'],
})
export class TestVideoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  // Video Popup
  isOpen = false;
  openPopup(): void {
    this.isOpen = true;
  }
  closePopup(): void {
    this.isOpen = false;
  }
}
