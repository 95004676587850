<div class="new-app-main-banner-gradient-area">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="new-app-main-banner-gradient-content">
          <h1>Medical Virtual Consultation Demo App</h1>
          <p>
            Get a feel of our apps by downloading and exploring the look and
            feel of our published demo apps.
          </p>
          <div class="new-app-download-content">
            <div class="btn-box color-wrap">
              <a
                href="https://play.google.com/apps/testing/com.appworldsa.medical.demo"
                class="playstore-btn"
                target="_blank"
                style="margin-bottom: 15px"
              >
                <img src="assets/img/play-store.png" alt="image" />
                Get It On
                <span>Google Play</span>
              </a>
              <a
                href="https://testflight.apple.com/join/3MZTsecy"
                class="applestore-btn"
                target="_blank"
                style="margin-bottom: 15px"
              >
                <img src="assets/img/apple-store.png" alt="image" />
                Download on the
                <span>Apple Store</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="new-app-main-banner-gradient-image">
          <img src="assets/img/apps/medical/banner.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
</div>
