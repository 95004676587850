<app-navbar-dark></app-navbar-dark>

<app-test-banner></app-test-banner>

<app-test-features></app-test-features>

<app-test-screenshots></app-test-screenshots>

<app-test-video></app-test-video>

<app-footer></app-footer>
