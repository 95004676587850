import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-test-banner',
  templateUrl: './test-banner.component.html',
  styleUrls: ['./test-banner.component.scss'],
})
export class TestBannerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
