<div class="main-banner" style="margin-bottom: 15px">
  <div class="container">
    <div class="content">
      <h1>
        Download our Hotel Demo App to get first hand experience of one of our
        custom built apps.
      </h1>
      <div class="new-app-download-content">
        <div class="btn-box color-wrap">
          <a
            href="https://play.google.com/apps/testing/com.appworldsa.hotel.demo"
            class="playstore-btn"
            target="_blank"
            style="margin-bottom: 15px"
          >
            <img src="assets/img/play-store.png" alt="image" />
            Get It On
            <span>Google Play</span>
          </a>
          <a
            href="https://testflight.apple.com/join/ywKxzdS8"
            class="applestore-btn"
            target="_blank"
            style="margin-bottom: 15px"
          >
            <img src="assets/img/apple-store.png" alt="image" />
            Download on the
            <span>Apple Store</span>
          </a>
        </div>
      </div>
      <img src="assets/img/apps/hotel/banner.png" alt="image" />
    </div>
  </div>
  <div class="divider"></div>
  <div class="banner-shape3">
    <img src="assets/img/shape/shape2.png" alt="image" />
  </div>
  <div class="banner-shape8">
    <img src="assets/img/shape/shape11.png" alt="image" />
  </div>
  <div class="banner-shape7">
    <img src="assets/img/shape/shape11.png" alt="image" />
  </div>
</div>
