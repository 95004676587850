<div class="footer-area">
  <div class="container">
    <div class="footer-content">
      <a routerLink="/" class="logo">
        <img src="assets/img/logo1.png" alt="logo" style="max-height: 150px" />
      </a>
      <ul class="social-links">
        <li>
          <a
            href="https://www.facebook.com/profile.php?id=61563862074129"
            target="_blank"
            ><i class="ri-facebook-fill"></i
          ></a>
        </li>
        <li>
          <a href="https://x.com/AppWorldSA" target="_blank"
            ><i class="ri-twitter-fill"></i
          ></a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/appworld_sa/?igsh=bGZmdXVtd2cwMWRr"
            target="_blank"
            ><i class="ri-instagram-fill"></i
          ></a>
        </li>
        <li>
          <a href="https://pin.it/6OjxOQuwC" target="_blank"
            ><i class="ri-pinterest-fill"></i
          ></a>
        </li>
        <li>
          <a href="https://www.youtube.com/@AppWorld-Pty" target="_blank"
            ><i class="ri-youtube-fill"></i
          ></a>
        </li>
        <li>
          <a
            href="https://api.whatsapp.com/send/?phone=%2B27662021775"
            target="_blank"
            ><i class="ri-whatsapp-fill"></i
          ></a>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a routerLink="/" class="nav-link">Home</a>
        </li>
        <li class="nav-item">
          <a routerLink="/how-to" class="nav-link">How it Works</a>
        </li>
        <li class="nav-item">
          <a routerLink="/terms-of-service" class="nav-link"
            >Terms of Service</a
          >
        </li>
        <li class="nav-item">
          <a routerLink="/privacy-policy" class="nav-link">Privacy Policy</a>
        </li>
        <li class="nav-item">
          <a routerLink="/faq" class="nav-link">FAQ's</a>
        </li>
        <li class="nav-item">
          <a routerLink="/contact-us" class="nav-link">Contact</a>
        </li>
      </ul>
      <p class="copyright">© AppWorld {{ currentYear }}</p>
    </div>
  </div>
</div>
