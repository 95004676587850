<div class="app-video-area pb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="app-intro-video-box">
          <img src="assets/img/apps/shop/video.png" alt="video-img" />
          <button
            class="video-btn popup-video"
            (click)="openPopup()"
            style="width: 50px; height: 50px"
          >
            <img
              style="
                vertical-align: top;
                margin-top: 8px;
                margin-left: 1px;
                height: 30px;
              "
              src="assets/img/icons/play.png"
              alt="play"
            />
          </button>
          <div class="intro-video-shape">
            <img src="assets/img/more-home/video/shape-3.png" alt="image" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="app-intro-video-content">
          <span class="sub-title">PROMO VIDEO</span>
          <h2>Watch Our Shop Demo App Video</h2>
          <p>
            The Shop Demo App is an innovative platform designed to showcase the
            capabilities of our new framework. It offers a seamless user
            experience for discovering, streaming, and managing hotels. Users
            can browse an extensive library, subscribe to their favorite shows,
            and create personalized playlists. The app's intuitive interface
            demonstrates the framework's prowess in handling complex data
            structures and delivering smooth audio streaming. With features like
            offline listening, push notifications for new episodes, and a robust
            search function, the Podcast Demo App exemplifies the framework's
            potential in building modern, scalable, and user-centric
            applications.
          </p>
          <a routerLink="/contact-us" class="default-btn">Get In Touch</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
  <div class="popup-inner">
    <iframe
      src="https://www.youtube.com/embed/CwckvdNYSIo"
      title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
    <button (click)="closePopup()" type="button" class="close-btn">
      <i class="ri-close-line"></i>
    </button>
  </div>
</div>
