<div class="new-app-download-wrap-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="new-app-download-content">
          <span class="sub-title">DOWNLOAD APP</span>
          <h2>Get Podcast Demo App from selected stores</h2>
          <p>
            Download our Podcast Demo App to get first hand experience of one of
            our custom built apps. We pride our apps based on how well they
            perform and the overall size of the app. All our demo apps are fully
            integrated with a backend micro services echo system to manage
            users, content and scale on demand.
          </p>
          <div class="btn-box color-wrap">
            <a
              href="https://play.google.com/store/apps/details?id=com.appworldsa.podcast.demo"
              class="playstore-btn"
              target="_blank"
            >
              <img src="assets/img/play-store.png" alt="image" />
              Get It On
              <span>Google Play</span>
            </a>
            <a
              href="https://testflight.apple.com/join/9emQdGQp"
              class="applestore-btn"
              target="_blank"
            >
              <img src="assets/img/apple-store.png" alt="image" />
              Download on the
              <span>Apple Store</span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="new-app-download-image text-end" data-aos="fade-up">
          <img src="assets/img/apps/podcast/banner.png" alt="app-img" />
          <div class="download-circle">
            <img
              src="assets/img/more-home/app-download/download-circle.png"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-download-shape-1">
    <img src="assets/img/more-home/app-download/shape-1.png" alt="image" />
  </div>
  <div class="app-download-shape-2">
    <img src="assets/img/more-home/app-download/shape-2.png" alt="image" />
  </div>
</div>
