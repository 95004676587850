<app-navbar-light></app-navbar-light>

<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Release Matrix</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>Release Matrix</li>
      </ul>
    </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
  <div class="banner-shape1">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div>
</div>

<div class="container" style="overflow-y: auto">
  <table style="width: 100%">
    <caption>
      <strong
        >Release matrix with links to all apps accessible on different
        platforms</strong
      >
      <br />
      <strong>Ready States:</strong>
      <br />
      POC - App does not have much working functionality. These types of apps
      are usually for UI/UX design.
      <br />
      Alpha - App has some functionality, but it's still in development.<br />
      Beta - App has fully fuctional features, but it's not yet ready for
      production use.<br />
      Production - App is production ready. Features have been tested and ready
      for public use.
    </caption>
    <thead>
      <tr>
        <td><h6>SaaS App</h6></td>
        <td><h6>Ready State</h6></td>
        <td><h6>Google Play</h6></td>
        <td><h6>App Store</h6></td>
        <td><h6>Website</h6></td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Test (Base App)</td>
        <td>Production</td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://play.google.com/store/apps/details?id=com.appworldsa.test"
            target="_blank"
            >Open Testing</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://testflight.apple.com/join/tkhQSqgK"
            target="_blank"
            >Test Flight</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://demotest.test.appworldsa.com"
            target="_blank"
            >Website
          </a>
        </td>
      </tr>
      <tr>
        <td>↪️Test (K53 Light Motor - English)</td>
        <td>Production</td>
        <td>
          <p>Processing...</p>
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://apps.apple.com/us/app/k53-light-motor/id6670366239"
            target="_blank"
            >Production</a
          >
          📱
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://k53-light-motor.test.appworldsa.com"
            target="_blank"
            >Website
          </a>
        </td>
      </tr>
      <tr>
        <td>↪️Test (K53 Heavy Motor - English)</td>
        <td>Production</td>
        <td>
          <p>Processing...</p>
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://apps.apple.com/us/app/k53-heavy-motor/id6670367100"
            target="_blank"
            >Production</a
          >
          📱
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://k53-heavy-motor.test.appworldsa.com"
            target="_blank"
            >Website
          </a>
        </td>
      </tr>
      <tr>
        <td>↪️Test (K53 Light Motor - English)</td>
        <td>Production</td>
        <td>
          <p>Processing...</p>
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://apps.apple.com/us/app/k53-motorcycle/id6670367191"
            target="_blank"
            >Production</a
          >
          📱
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://k53-motorcycle.test.appworldsa.com"
            target="_blank"
            >Website
          </a>
        </td>
      </tr>
      <tr>
        <td>Learning (Base App)</td>
        <td>Alpha</td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://play.google.com/store/apps/details?id=com.appworldsa.lms"
            target="_blank"
            >Open Testing</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://testflight.apple.com/join/dtT1swjP"
            target="_blank"
            >Test Flight</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://demolms.lms.appworldsa.com"
            target="_blank"
            >Website
          </a>
        </td>
      </tr>
      <tr>
        <td>Streaming (Base App)</td>
        <td>POC</td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://play.google.com/store/apps/details?id=com.appworldsa.audio"
            target="_blank"
            >Open Testing</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://testflight.apple.com/join/YyxcAetR"
            target="_blank"
            >Test Flight</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://demopodcast.podcast.appworldsa.com/#/auth/sign-in"
            target="_blank"
            >Website
          </a>
        </td>
      </tr>
      <tr>
        <td>Accommodation (Base App)</td>
        <td>POC</td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://play.google.com/store/apps/details?id=com.appworldsa.hotel"
            target="_blank"
            >Open Testing</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://testflight.apple.com/join/EhYfumBn"
            target="_blank"
            >Test Flight</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://demohotel.hotel.appworldsa.com"
            target="_blank"
            >Website
          </a>
        </td>
      </tr>
      <tr>
        <td>Crypto NFT (Base App)</td>
        <td>POC</td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://play.google.com/store/apps/details?id=com.appworldsa.nft"
            target="_blank"
            >Open Testing</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://testflight.apple.com/join/DmXCmcDh"
            target="_blank"
            >Test Flight</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://demonft.nft.appworldsa.com"
            target="_blank"
            >Website
          </a>
        </td>
      </tr>
      <tr>
        <td>Shop (Base App)</td>
        <td>POC</td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://play.google.com/store/apps/details?id=com.appworldsa.shop"
            target="_blank"
            >Open Testing</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://testflight.apple.com/join/qjBtg9pQ"
            target="_blank"
            >Test Flight</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://demostore.store.appworldsa.com"
            target="_blank"
            >Website
          </a>
        </td>
      </tr>
      <tr>
        <td>Medical (Base App)</td>
        <td>POC</td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://play.google.com/store/apps/details?id=com.appworldsa.medical"
            target="_blank"
            >Open Testing</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://testflight.apple.com/join/5JCdmJKa"
            target="_blank"
            >Test Flight</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://demomedical.medical.appworldsa.com/"
            target="_blank"
            >Website
          </a>
        </td>
      </tr>
      <tr>
        <td>Wallet (Base App)</td>
        <td>POC</td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://play.google.com/store/apps/details?id=com.appworldsa.wallet"
            target="_blank"
            >Open Testing</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://testflight.apple.com/join/rPvw9uv4"
            target="_blank"
            >Test Flight</a
          >
        </td>
        <td>
          <a
            style="
              color: dodgerblue;
              font-weight: 600;
              text-decoration: underline;
            "
            href="https://demowallet.wallet.appworldsa.com/"
            target="_blank"
            >Website
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-footer></app-footer>
